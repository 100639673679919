import React, { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../context';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { H2 } from '../theme/typography';

import Styles from './styles';
import { ReactComponent as MailLogo } from '../../assets/svgs/mail.svg';
import Hr from '../../components/hr';
import MessageBar from '../message-bar';

const MessagesCards = (props) => {
  const { userContext } = useContext(AppContext);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [isMagic, setIsMagic] = useState(true);
  const [hasDocument, sethasDocument] = useState(false);
  // const [messages, setMessages] = useState(undefined);
  const [showModal, setShowModal] = useState(false);

  const getFormatedDate = (dateToFormat) => {
    let formatedDate = '';
    if (typeof dateToFormat === 'string') {
      formatedDate = new Date(dateToFormat).toDateString().slice(4);
    }
    return formatedDate;
  };

  const openModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   let user_info = userContext ;
  // //   if (user_info) {
  // //     // setIsLoggedIn(true);
  // //     if(user_info['org-user-type'] && user_info['org-user-type']=== "magic"){
  // //       // setIsMagic(true);
  // //     }else{
  // //       // setIsMagic(false);
  // //     }
  // //   }
  // //  else {
  // //     // setIsLoggedIn(false);
  // //   }
  // },[isWaitingForRequest]);

  // useEffect(() => {
  //   console.log('messages 54', props.messages);
  //   console.log('props.messages[0].title 55', typeof props.messages !== 'undefined' );

  //   // if ( (typeof messages === 'undefined' ) ) {
  //   //   getMessages();
  //   // }
  // },[]);

  return (
    <>
      {props.messages && props.messages.length > 0 && (
        <Styles>
          <Container className="container-card">
            <Row className="justify-content-around">
              {props.messages && props.messages.length > 0 && (
                <Col md={12} sm={12} className="p-0">
                  <div className="card card-home1-message text-center pt-3 ">
                    <div className="card-body-messages">
                      <div className="message_wrapper">
                        <div className="message_header">
                          <div
                            className="message_header_tittle"
                            dangerouslySetInnerHTML={{
                              __html: props.messages[0].title,
                            }}
                          ></div>

                          <div className="message_header_date">
                            {getFormatedDate(props.messages[0]['modified'])}
                          </div>
                          <div className="logo-wrapper">
                            <MailLogo stroke="#02b1c8"></MailLogo>
                          </div>
                        </div>
                        <Hr />
                        <div className="message_content">
                          <div
                            className={
                              hasDocument
                                ? 'rendered_message_document'
                                : 'rendered_message'
                            }
                            dangerouslySetInnerHTML={{
                              __html: props.messages[0].message,
                            }}
                          ></div>
                          {hasDocument && (
                            <div className="message_document">
                              <embed
                                src="http://www.africau.edu/images/default/sample.pdf"
                                width="200"
                                height="200"
                                type="application/pdf"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-white ">
                      <Hr />
                      <a
                        href=""
                        className="text-footer"
                        onClick={(e) => {
                          openModal(e);
                        }}
                      >
                        See all Messages
                      </a>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
          {props.messages && props.messages.length > 0 && (
            <Modal
              size="lg"
              fullscreen={true}
              show={showModal}
              onHide={handleCloseModal}
              centered
              style={{ overflow: 'hidden' }}
              // scrollable
            >
              <Modal.Header style={{ height: '20%', border: '0px' }}>
                <Modal.Title style={{ width: '100%', 'text-align': 'center' }}>
                  <H2 h2> {props.messages.length} Messages </H2>{' '}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                style={{
                  maxHeight: '400px',
                  border: '0px',
                  overflow: 'auto',
                  width: '100%',
                }}
                className="modal-body"
              >
                {props.messages.map((message) => {
                  return (
                    <Container
                      style={{ margin: '16px' }}
                      key={'ContainerMessageCard' + props.messages.id}
                    >
                      <Row>
                        <Col>
                          <MessageBar
                            key={'message' + message.id}
                            message={message}
                          ></MessageBar>
                        </Col>
                      </Row>
                    </Container>
                  );
                })}
              </Modal.Body>
              <Modal.Footer
                style={{ padding: '12px', border: '0px', height: '80%' }}
              ></Modal.Footer>
            </Modal>
          )}
        </Styles>
      )}
    </>
  );
};

export default MessagesCards;
