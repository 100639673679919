import React, { useState, useContext,useEffect,useRef,useLayoutEffect } from 'react';
import { GoogleBtnContainer } from  './styles.js';
import useIsSmallScreen from '../../../useIsSmallScreen/useIsSmallScreen.js';




const GoogleBtn = (props) => {
  const ref = useRef(null);
  const [btnWidth, setBtnWidth] = useState(326); // You don't know the real height yet
  // const [googleReady, setGoogleReady] =useState(window.google.accounts.id);

  const [googleInitialise, setGoogleInitialize] =useState(false);
  const [btnRendered, setBtnRendered] =useState(false);
  const isSmallScreen =  useIsSmallScreen();


    useLayoutEffect(() => {
    //  let { width } = ref.current.getBoundingClientRect();
    if(isSmallScreen ){
      let { width } = ref.current.getBoundingClientRect();
      // window.addEventListener("orientationchange", handleorientationchange);
      if(typeof width  === 'number'){
        setBtnWidth(width);}
      // setUpdate(()=>false)
      } 
      // return ()=>{
      //   window.removeEventListener("orientationchange");
      // }
  },[]);

  
// useEffect(() => {
//   // console.log('window.google', window.google);
//   console.log('window.google', window.google);

//   console.log('window.google', window.google.accounts.id);

  
//   if(window && window.google&& window.google.accounts && window.google.accounts.id){
//     window.google.accounts.id.initialize({
//       client_id: "948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com",
//       // use_fedcm_for_prompt: true, // for migration in the future
//       auto_prompt:"false",
//       callback: (res, error) => {
//           // This is the function that will be executed once the authentication with google is finished
//           if (res) {
//             window.location.replace( `${window.BASE_URL_USER}alpha_bet_login?credential=${res.credential}`);
//           };
//           if (error) {
//             console.log("error", error);
//           }
//       },
//     });
//     setGoogleInitialize(true);
//   }
// }, []);
useEffect(() => {  
  if(window && window.google&& window.google.accounts && window.google.accounts.id){
    window.google.accounts.id.initialize({
      client_id: "948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com",
      // use_fedcm_for_prompt: true, // for migration in the future
      auto_prompt:"false",
      callback: (res, error) => {
          // This is the function that will be executed once the authentication with google is finished
          if (res) {
            window.location.replace( `${window.BASE_URL_USER}alpha_bet_login?credential=${res.credential}`);
          };
          if (error) {
            console.log("error", error);
          }
      },
    });
    setGoogleInitialize(true);
  }
});  // we may need [window] dependency 



  useEffect(() => {
    // we need width measurement , google client initialized and 
    //  we need to be sure no to render the btn multiple times
    if( googleInitialise &&  btnWidth && typeof btnWidth === 'number' && !btnRendered){
      renderButton();
    }
  }, [googleInitialise, btnWidth,btnRendered]);
  

  const renderButton =()=>{
    window.google.accounts.id.renderButton(ref.current, {
      theme: 'outline',
      size: 'large',
      type: 'standard',
      text: 'continue_with',
      shape: 'rectangular',
      width: btnWidth,
      });
    setBtnRendered(true);
  };




  // window.onload = function () {
  //   window.google.accounts.id.initialize({
  //     client_id: "948003101733-00qi1fj2h81lsqlk8l53ceg8ekdbs8lj.apps.googleusercontent.com",
  //     auto_prompt:"false",
  //     callback: (res, error) => {
  //         // This is the function that will be executed once the authentication with google is finished
  //         if (res) {
  //           const config = {
  //             method: 'post',
  //             url: `${window.BASE_URL_USER}alpha_bet_login`,
  //             withCredentials : true,
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             data: {'token': res.credential},
  //           };
  //           axios(config);
  //         };
  //         if (error) {
  //           console.log("error", error);
  //         }
  //     },
  //   });
  //   google.accounts.id.prompt();
  // };
  


  return (
    <>
    {typeof btnWidth !=='undefined' && ( 
      
      <GoogleBtnContainer  key={'btn_'+btnWidth}>
        <div  ref={ref} style={{pointerEvents:props.enable?"all":"none"}}></div>
      </GoogleBtnContainer>
      
      )}
      </>
  );
};

export default GoogleBtn;
